<template>
  <div class="group-item-warp">
    <Row :gutter="20">
      <Col span="5">
        <Card title="群组列表" dis-hover>
          <div slot="extra">
            <Button
              type="success"
              icon="md-add"
              shape="circle"
              size="small"
              @click="renameGroup({})"
            />
          </div>
          <List class="group-list-warp">
            <ListItem
              class="group-item"
              :class="{ 'group-active': current == index }"
              v-for="(item, index) in groups"
              :key="index"
              @click.native="getCurrent(item, index)"
            >
              <ListItemMeta :title="item.friendly_name" />
              <template slot="action" v-if="current == index">
                <Button
                  type="success"
                  icon="md-create"
                  shape="circle"
                  size="small"
                  class="m-r-5"
                  @click.stop="renameGroup(item)"
                />
                <Button
                  type="info"
                  icon="md-flower"
                  shape="circle"
                  size="small"
                  class="m-r-5"
                  @click.stop="showModal(item)"
                />
                <Button
                  type="error"
                  icon="md-trash"
                  shape="circle"
                  size="small"
                  class="m-r-5"
                  @click.stop="delGroup(item)"
                />
              </template>
            </ListItem>
          </List>
        </Card>
      </Col>
      <Col span="10">
        <Card title="群组设备列表" dis-hover class="device-warp">
          <div class="add-device-warp p-10">
            <div class="add-device-title m-b-10 f-bold f-14">请选择设备：</div>
            <Row :gutter="10">
              <Col span="10">
                <SelectDevice v-model="deviceId" :devices="devicesByType" />
              </Col>
              <Col span="10">
                <Button type="success" @click="addGroupMember">添加设备</Button>
                <Button type="error" class="m-l-5" @click="delAllGroupDevice">一键删除</Button>
              </Col>
            </Row>
          </div>
          <Row justify="start" align="top" :gutter="10"  class="device-list">
            <template v-if="groupSelected.members && groupSelected.members.length">
            <Col
              span="6"
              v-for="(item, index) in groupSelected.members"
              :key="index"
            >
              <Card class="m-t-10" @mouseover.native="getDeviceCurrent(item, index)">
                <div class="device-item">
                  <span>{{ deviceNameById[item.ieee_address] }}</span>
                  <Button icon="md-close" shape="circle" size="small" @click.stop="delGroupDevice(item.ieee_address)" v-if="deviceCurrent == index" />
                </div>
              </Card>
            </Col>
          </template>
          <Col span="24" class="text-c m-t-20" v-else>暂无群组列表！</Col>
          </Row>

        </Card>
      </Col>
      <Col span="9">
        
        <Card title="群组场景" dis-hover class="device-warp group-scene-warp">
          <div slot="extra">
            <Button
              type="success"
              icon="md-add"
              shape="circle"
              size="small"
              @click="renameScene({})"
            />
          </div>
          
          <Row justify="start" align="top" :gutter="10"  class="device-list">
            <template v-if="groupSelected.scenes && groupSelected.scenes.length">
            <Col
              span="12"
              v-for="(item, index) in groupSelected.scenes"
              :key="index"
            >
              <Card class="m-t-10" @mouseover.native="getSceneCurrent(item, index)">
                <div class="device-item">
                  <span>{{ item.name }}</span>
                  <div v-if="sceneCurrent == index">
                    <Button type="text" size="small" @click.stop="callScene(item)" class="m-r-5">召唤场景</Button>
                    <Button icon="md-brush" shape="circle" size="small" @click.stop="renameScene(item)" class="m-r-5" />
                    <Button icon="md-close" shape="circle" size="small" @click.stop="delGroupScene(item)" />
                  </div>
                </div>
              </Card>
            </Col>
          </template>
          <Col span="24" class="text-c m-t-20" v-else>暂无群组场景列表！</Col>
          </Row>
        </Card>
      </Col>
    </Row>
    <GroupModal :modal="modal" @change="groupSetting" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import GroupModal from "../../internetOfThing/modal.vue";
import SelectDevice from "../SelectDevice.vue";
import iView from "view-design";
import { util } from "@/libs";
export default {
  components: { GroupModal, SelectDevice },
  data() {
    return {
      typeShow: ["TS0503B", "TS0505B", "TS0601", "TS0011", "TS0013"],
      current: null,
      deviceCurrent: null,
      sceneCurrent: null,
      deviceId: '',
      groupSelected: {},
      groupName: null,
      warningTxt: null,
      sceneName: null,
      modal: {
        show: false,
        group: "",
        typeShow: false,
        disabled: false,
      },
    };
  },
  computed: {
    ...mapState("client", [
      "client",
      "classroomId",
      "groups",
      "devices",
      "deviceNameById",
    ]),
    devicesByType() {
      let data = [];
      if (this.devices.length) {
        data = this.devices.filter((item) =>
          this.typeShow.includes(item.model_id)
        );
      }
      return data;
    },
    // // 根据设备ID获取name 返回{id: 设备名}
    // deviceNameById() {
    //   let data = {}
    //   if(this.devices.length) {
    //     this.devices.forEach((val, key, arr) => {
    //       console.log(key, val, arr)
    //       data[val.ieee_address] = val.friendly_name
    //       return
    //     });
    //   }
    //   return data
    // }
  },
  methods: {
    /** 群组模块 start */
    // 获取当前所选群组
    getCurrent(group, index) {
      console.log("getCurrent:", index);
      this.current = index;
      this.deviceCurrent = null;
      this.sceneCurrent = null;
      this.groupSelected = group;
    },
    // 添加、重命名群组
    renameGroup(group = {}) {
      console.log("renameGroup group:", group);
      const _this = this;
      this.$Modal.confirm({
        title: "智能群组名称：",
        loading: true,
        render: (h) => {
          return [
            h("Input", {
              props: {
                value: group.friendly_name,
                placeholder: "请输入智能群组名称...",
              },
              on: {
                input: (val) => {
                  this.groupName = val;
                  this.warningTxt = null;
                },
              },
            }),
            h(
              "p",
              { domProps: { className: "m-t-5 f-12 c-red" } },
              this.warningTxt
            ),
          ];
        },
        onOk: function() {
          if (!_this.groupName) {
            _this.warningTxt = "*请输入智能群组名称!";
            setTimeout(() => {
              this.buttonLoading = false;
            }, 1000);
            return;
          }
          if (group.id) {
            // 重命名
            _this.$store.dispatch("client/renameGroup", {
              from: group.friendly_name,
              to: _this.groupName,
            });
          } else {
            // 新增
            _this.$store.dispatch("client/addGroup", {
              friendly_name: _this.groupName,
              id: util.uuid(),
            });
          }

          _this.groupName = null;
          iView.Modal.remove();
        },
        onCancel: () => {
          this.groupName = null;
          this.warningTxt = null;
        },
      });
    },
    // 打开群组弹框
    showModal(group) {
      Object.assign(this.modal, { show: true, group: group.friendly_name });
    },
    // 设置群组参数
    groupSetting(res) {
      if (Object.keys(res).length == 0) {
        this.$Message.warning("请先选择配置参数！");
        return;
      }
      this.$store.dispatch("client/onPublish", {
        topic: `zigbee/${this.classroomId}/${this.modal.group}/set`,
        params: res,
      });
      Object.assign(this.modal, { show: false });
    },
    // 删除群组
    delGroup(group) {
      const _this = this;
      this.$Modal.confirm({
        title: "删除",
        content: `确定删除当前群组--[${group.friendly_name}]吗？`,
        onOk() {
          _this.$store.dispatch("client/delGroup", group.friendly_name);
        },
      });
    },
    /** 群组模块 end */

    /** 群组设备模块 */
    // 获取当前群组设备
    getDeviceCurrent(device, index) {
      this.deviceCurrent = index
    },
    // 添加群组设备
    addGroupMember() {
      if(!this.deviceId) {
        this.$Message.warning("请先选择需要添加的群组设备！")
        return;
      }
      if(!this.groupSelected.friendly_name) {
        this.$Message.warning("请先选择群组！")
        return;
      }
      let params = { group: this.groupSelected.friendly_name, device: this.deviceId }
      this.$store.dispatch('client/addGroupMember', params)
    },
    // 将所有群组中所存在的所选设备一键删除
    delAllGroupDevice() {
      if(!this.deviceId) {
        this.$Message.warning("请先选择需要删除的群组设备！")
        return;
      }
      this.$Modal.confirm({
        title: '删除',
        content: `确定要将该设备[${this.deviceNameById[this.deviceId]}]从所有群组中删除吗？`,
        onOk: () => {
          this.$store.dispatch('client/delAllGroupMember', {
            device: this.deviceId
          })
        }
      })
    },
    // 删除群组设备
    delGroupDevice(deviceId) {
      this.$Modal.confirm({
        title: '删除',
        content: `确定从群组[${this.groupSelected.friendly_name}]中删除设备-${this.deviceNameById[deviceId]}吗?`,
        onOk: () => {
          this.$store.dispatch("client/delGroupDevice", { group: this.groupSelected.friendly_name, device: deviceId })
        }
      })
    },
    /** 群组设备 end */
    /** 群组场景模块 */
    // 获取当前场景下标
    getSceneCurrent(scene, index) {
      this.sceneCurrent = index
    },
    // 召唤场景
    callScene(scene) {
      this.$store.dispatch("client/callGroupScene", {
        topic: `zigbee/${this.classroomId}/${this.groupSelected.friendly_name}/set`,
        params: { "scene_recall": scene.id }
      })
    },
    // 重命名场景
    renameScene(scene={}) {
      if(!this.current && !this.groupSelected.friendly_name) {
        this.$Message.warning("请先选择群组！")
        return
      }
      const _this = this;
      this.$Modal.confirm({
        title: '智能场景名称:',
        loading: true,
        render: (h) => {
          return [
            h("Input", {
              props: {
                value: scene.name,
                placeholder: "请输入智能场景名称...",
              },
              on: {
                input: (val) => {
                  this.sceneName = val;
                  this.warningTxt = null;
                },
              },
            }),
            h(
              "p",
              { domProps: { className: "m-t-5 f-12 c-red" } },
              this.warningTxt
            ),
          ];
        },
        
        onOk: function() {
          if (!_this.sceneName) {
            _this.warningTxt = "*请输入智能场景名称!";
            setTimeout(() => {
              this.buttonLoading = false;
            }, 1000);
            return;
          }
          let topic =  `zigbee/${_this.classroomId}/${_this.groupSelected.friendly_name}/set`
          if (scene.id) {
            // 重命名
            _this.$store.dispatch("client/renameGroupScene", {
              topic,
              params: {
                "scene_rename": {
                  ID: scene.id,
                  name: _this.sceneName
                }
              }
            });
          } else {
            // 新增
            _this.$store.dispatch("client/addGroupScene", {
              topic,
              params: {
                "scene_store": {
                  name: _this.sceneName,
                  ID: util.uuid(),
                }
              }
            });
          }
          _this.sceneName = null;
          iView.Modal.remove();
        },
        onCancel: () => {
          this.sceneName = null;
          this.warningTxt = null;
        },
      })
    },
    // 删除场景
    delGroupScene(scene) {
      this.$Modal.confirm({
        title: "删除场景",
        content: `确定删除群组--${this.groupSelected.friendly_name}下的场景--${scene.name}吗？`,
        onOk: () => {
          this.$store.dispatch("client/delGroupScene", {
            topic: `zigbee/${this.classroomId}/${this.groupSelected.friendly_name}/set`,
            params: { scene_remove: scene.id }
          })
        },
      });
    }

  },
  mounted() {
    console.log("mounted:", this.current)
  }
};
</script>

<style lang="less" scoped>
@box-shadow: 0 2px 10px rgba(125, 126, 128, 0.16);
@border-color: #dcdee2;
.group-item-warp {
  .group-list-warp {
    height: 560px;
    max-height: 560px;
    overflow-y: scroll;
    .group-item {
      padding-left: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .group-active {
      background: #b1cbe6;
      box-shadow: @box-shadow;
      /deep/.ivu-list-item-meta-title {
        color: #fff;
      }
    }
  }
  .device-warp {
    .add-device-warp {
      width: 100%;
      height: 100px;
      border: dashed 1px @border-color;
      border-radius: 10px;
    }
    .device-list {
      max-height: 460px;
      overflow-y: scroll;
      .device-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20px;
      }
    }

  }
  .group-scene-warp {

  }
}
</style>
